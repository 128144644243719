<template>
  <b-card
    no-body
    v-if='personInfo'
    class='wameed-card m-0'>

    <b-card-header class='align-items-start tab-title'>
      <h5 class='text-med-18 text-font-main'>
        {{ title }}
      </h5>
    </b-card-header>

    <div class='person-info'>
      <div class='data'>

        <template v-if='showImageDiv'>
          <img v-if='personInfo.image && showImage' :src=personInfo.image :alt=personInfo.name @error='onError'
               class='person-img'>
          <div v-else class='person-img'>
            <span> <d-person-icon /> </span>
          </div>
        </template>

        <div class='col'>
          <router-link class='name' :to='link' v-if='link!=null'>{{ personInfo.name }}</router-link>
          <a class='phone' @click='openWhatsapp'>{{ personInfo.phone }}</a>
          <div class='text'>{{ personInfo.count }} {{ personInfo.status }}</div>
          <div class='text' v-if='personInfo.address'>{{ personInfo.address }}</div>
        </div>
      </div>
      <div class='d-flex' style='gap:12px;align-items:end' v-if='showBtns && canManage'>
        <wameed-btn
          :disabled='!enableBtn'
          variant='gray'
          size='sm'
          :title='btn'
          :classes='`text-book-18  rounded-12 w-unset px-4 ${btnColor}`'
          block
          @onClick='onClick'
        />
        <slot />
      </div>
    </div>
  </b-card>
</template>

<script>
import { WameedBtn } from 'wameed-ui/dist/wameed-ui.esm';

export default {
  components: { WameedBtn },
  data() {
    return { showImage: true };
  },
  props: {

    client: {
      type: Boolean,
      default: false
    },
    canManage: {
      type: Boolean,
      default: false
    },
    btn: String,
    title: String,
    link: {},
    btnColor: {
      type: String,
      default: 'secondary-btn'
    },
    showBtns: {
      type: Boolean,
      default: true
    },
    enableBtn: {
      type: Boolean,
      default: true
    },
    info: null
  },
  computed: {
    personInfo() {
      return this.info;
    },
    showImageDiv() {
      return this.personInfo != null && Object.prototype.hasOwnProperty.call(this.personInfo, 'image');
    }
  },
  methods: {
    onError() {
      this.showImage = false;
    },
    onClick() {
      this.$emit('onClick');
    },
    openWhatsapp() {
        window.open(`https://wa.me/+967${this.personInfo.phone}`, '_blank');
    }
  }
}
;
</script>
