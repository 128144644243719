<template>
  <b-modal
    v-model='show'
    content-class='package-modal rounded-8 assign-driver-modal'
    dialog-class='wameed-modal-md'
    no-fade
  >
    <template slot='modal-header-close'>
      <close-icon />
    </template>
    <template slot='modal-title'>
      <div class='d-block text-center'>
        <h3 class='text-reg-18'>
          {{ $t('orders.modal.driver.assign') }}
        </h3>
      </div>
    </template>


    <div class='body'>

      <div class='search-header'>
        <div class='search-filters'>

          <div class='filter'
               :class="form.filter===2?'active':''"
               @click='()=>filterClick(2)'>
            <span>{{ $t('common.all') }}</span>
          </div>

          <div class='filter'
               :class="form.filter===1?'active':''"
               @click='()=>filterClick(1)'>
            <span>{{ $t('status.available') }}</span>
          </div>

          <div class='filter'
               :class="form.filter===0?'active':''"
               @click='()=>filterClick(0)'>
            <span>{{ $t('status.not_available') }}</span>
          </div>

        </div>
        <div class=''>
          <text-input
            id='login-email'
            v-model='form.search'
            icon='search-icon'
            is-append
            input-classes='text-reg-14 '
            name='search'
            :placeholder="$t('common.search') + ' ...'"
            field-classes='mb-0 '
          />
        </div>
      </div>

      <div class='branch-filter mb-5'>
        <select class='form-control' v-model='form.branch' @change='filterByBranch'>
          <option value=''>{{ $t('common.all') }}</option>
          <option v-for='branch in getBranches' :value='branch.id' :selected='vendorBranchId==branch.id'>{{ branch.name
            }}
          </option>
        </select>
      </div>

      <div class='driver-list'>
        <driver-card v-for='driver in filterDrivers'
                     :key='driver.id'
                     :data='driver'
                     @onClick='assignDriver(driver.id)'
        />
      </div>
    </div>


    <template slot='modal-footer' class='d-none'>

    </template>
  </b-modal>
</template>

<script>
import { WameedTextInput as TextInput } from 'wameed-ui/dist/wameed-ui.esm';
import DriverCard from '@/views/pages/orders/components/driverCard';
import { mapGetters } from 'vuex';


export default {
  components: {
    DriverCard,
    TextInput
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },

    orderId: [Number, String],
    category: {
      type: Object,
      default: null
    },
    vendorBranchId: {
      type: Number,
      default: null
    }

  },

  data() {
    return {
      form: {
        search: '',
        branch: '',
        filter: 2
      },
      showSuccessModal: false
    };
  },
  computed: {
    ...mapGetters({
      drivers: 'vendor/orders/getDrivers',
      getBranches: 'vendor/orders/getBranches'
    }),
    filterDrivers() {

      return this.drivers.filter((driver) => {
        return (driver.status == this.form.filter || this.form.filter == 2) &&
          (driver.name.toLowerCase().includes(this.form.search.toLowerCase()) || this.form.search == '') &&
          (driver.platform_branch_id == this.form.branch || this.form.branch == '');
      });
    },
    show: {
      get() {
        this.form.branch = this.vendorBranchId;
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.form = {
            search: '',
            branch: '',
            filter: 2
          };
          this.$emit('close');
        }
      }
    }
  },
  methods: {

    async filterClick(filter) {
      await this.loadData();
      this.form.filter = filter;
    },

    filterByBranch(branchId) {

      this.form.branch = branchId.target.value;
    },


    assignDriver(id) {
      this.$store.dispatch('vendor/orders/assignDriver', {
        'driver_id': id,
        'order_id': this.orderId
      }).then(() => {
        this.closeModal();
      });
    },


    closeModal() {
      this.show = false;
      this.form = {
        search: '',
        filter: 2
      };
    },
    loadData() {
      this.$store.dispatch('vendor/orders/drivers', this.orderId);
    }
  },
  mounted() {
    this.loadData();
  }
};
</script>
